import axios from 'axios';
import url from 'url';
import { baseURL } from 'utils/AxiosInstance';

const getUrl = (contentType, key, size) => {
  return new Promise((resolve, reject) => {
    const options = {
      params: {
        key,
        type: contentType,
        size: size,
      },
    };
    axios
      .get(`${baseURL}/api/s3/get-url`, options)
      .then((res) => {
        resolve(res.data.url);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const uploadObject = ({ file, destination, contentType }, onProgress) => {
  return new Promise((resolve, reject) => {
    let size = file.size;
    let key = destination;
    getUrl(contentType, key, size).then((putURL) => {
      onProgress(true, 50);
      const options = {
        params: {
          Key: key,
          ContentType: contentType,
          // ACL: "public-read"
        },
        headers: {
          'Content-Type': contentType,
          // ACL: "public-read"
        },
      };

      axios
        .put(putURL, file, options)
        .then((res) => {
          const { protocol, host, pathname } = url.parse(putURL);
          let { lastModified, size } = res.config.data;
          let fileEtag = `${size}${lastModified}`;
          let result = {
            publicId: `s3/${key}`,
            name: file.name,
            fileEtag,
            webContentLink: `${protocol}//${host}${pathname}`,
            size,
          };
          resolve(result);
        })
        .catch((err) => {
          console.error('err', err);
          reject(err);
        });
    });
  });
};

export default {
  uploadObject,
};
