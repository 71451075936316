import { GlobalProvider } from 'context/GlobalContext';
import { LanguageProvider } from 'context/LanguageContext';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './app.css';
import './i18n';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
      <LanguageProvider>
        <SnackbarProvider maxSnack={5} preventDuplicate >
          <GlobalProvider>
            <App />
          </GlobalProvider>
        </SnackbarProvider>
      </LanguageProvider>
    </BrowserRouter>
);
