import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
interface IGlobalContext {
  isError: boolean;
  setIsError: (isError: boolean) => void;
  errorMessage: string;
  setErrorMessage: (errorMessage: string) => void;
  successMessage: string;
  setSuccessMessage: (successMessage: string) => void;
  enqueueSnackbar: (message: string, options?: any) => void;
}

const GlobalContext = createContext<IGlobalContext>({} as IGlobalContext);

export const GlobalProvider = ({ children }: any) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    // enqueueSnackbar('Welcome to Design Builder', {});
  }, []);

  return (
    <GlobalContext.Provider value={{} as IGlobalContext}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
