import {
  Autocomplete,
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import ButtonCustom from 'components/ButtonCustom';
import CustomTableCell from 'components/CustomTableCell';
import { useSnackbar } from 'notistack';
import CreditTransaction from 'pages/BusinessAccount/CreditTransaction';
import DebitTransaction from 'pages/BusinessAccount/DebitTransaction';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { adminTopUp } from 'services/business-setting-service';
import shortid from 'shortid';
import { IAdminTopUp } from 'utils/contracts';
import { package_options } from 'utils/Formatter';
import { calcTotalAccountPoints, getErrorHtmlContent, totalDebitPoints } from 'utils/utils';

const AddBusinessCredits = (props: any) => {
  const { organizations, refetch, organisationCallback } = props;

  const { t } = useTranslation();
  const [packageSection, setPackage] = useState('');
  const [sum, setSum] = useState('');
  const [credits, setCredits] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const textFieldStyles = {
    '& .MuiInputBase-root': {
      height: '50px', // Custom height
      padding: '10px 0px', // Adjust padding as needed
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 13px) scale(1)', // Adjust label position
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -10px) scale(0.75)',
    },
  };

  let list = [];
  for (let property in organizations?.transactions) {
    if (organizations?.transactions.hasOwnProperty(property)) {
      const transaction = organizations?.transactions[property];
      if (transaction.status === 'success') list.push(transaction);
    }
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const mutation = useMutation(adminTopUp, {
    onSuccess: () => {
      enqueueSnackbar(t('BUSINESS_ADMIN_CREDIT_ADD_SUCESS'), {
        variant: 'success',
      });
      refetch();
    },
    onError: (error: any) => {
      enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
      console.log('onError', error);
    },
  });

  const addCreditsHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    setisLoading(true);
    const topUpData: IAdminTopUp = {
      ProdQuantity: 1,
      OrganizationId: organizations.id,
      ProductDesc: description,
      TransactionId: shortid.generate(),
      ProductID: packageSection,
    };
    if (packageSection === 'CUSTOM') {
      topUpData.suminfull = sum;
      topUpData.TotalPoints = credits;
    }
    mutation.mutate(topUpData);
    setisLoading(false);
  };

  const disableInputs = useMemo(() => {
    if (packageSection === 'CUSTOM') {
      setDescription('SPECIAL');
    } else {
      setDescription('');
    }
    if (!packageSection || packageSection !== 'CUSTOM') {
      return true;
    }
  }, [packageSection]);

  const totalCredits = useMemo(
    () => calcTotalAccountPoints(organizations),
    [organizations]
  );
  const totalDebits = useMemo(
    () => totalDebitPoints(organizations),
    [organizations]
  );



  return (
    <Box
      sx={{
        mx: "1rem",
      }}
    >
      <form>
        <Box className="business_credits_header">
          <Box sx={{ textAlign: "flex-start" }}>
            <Typography variant="h5" fontWeight="bold">
              {totalCredits}    {t("EDIT_BUSINESS_HEADER_BALANCE")}
            </Typography>

          </Box>
          <Box className="business_credits_header_valid">
            <Box>
              <Autocomplete
                clearIcon={<></>}
                options={package_options}
                getOptionLabel={(option: any) => option.value}
                onChange={(_, value: any) => {
                  setPackage(value?.value);
                }}
                size='small'

                renderInput={(params) => (
                  <TextField
                    sx={{ width: "220px" }}
                    {...params}
                    label={t("ADMIN_ADD_BUSINESS_CREDIT_PACKAGE_SELECT")}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      sx: { height: "50px" },
                    }}
                  />
                )}
              />
            </Box>
            <Box>
              <TextField
                name="sum"
                label={t("ADMIN_ADD_BUSINESS_CREDIT_SUM")}
                type="text"
                placeholder={t("ADMIN_ADD_BUSINESS_CREDIT_SUM")}
                value={sum}
                onChange={(e) => setSum(e.target.value)}
                disabled={disableInputs}
                sx={textFieldStyles}
              />
            </Box>
            <Box>
              <TextField
                name="credit"
                label={t("ADMIN_ADD_BUSINESS_CREDIT_POINTS")}
                type="text"
                size='small'
                placeholder={t("ADMIN_ADD_BUSINESS_CREDIT_POINTS")}
                value={credits}
                onChange={(e) => setCredits(e.target.value)}
                disabled={disableInputs}
                sx={textFieldStyles}
              />
            </Box>
            <Box>
              <TextField
                name="description"
                label={t("ADMIN_ADD_BUSINESS_CREDIT_DESCRIPTION")}
                type="text"
                placeholder={t("ADMIN_ADD_BUSINESS_CREDIT_DESCRIPTION")}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                disabled={disableInputs}
                sx={textFieldStyles}
              />
            </Box>
            <Box>
              <ButtonCustom
                color="primary"
                type="submit"
                variant="contained"
                onClick={addCreditsHandler}
              >
                {t("ADMIN_ADD_BUSINESS_CREDIT_BUTTON")}
              </ButtonCustom>
            </Box>
          </Box>
        </Box>
      </form>

      <Box className="business_credits_body">
        <TableContainer
          component={Paper}
          sx={{
            mt: "1rem",
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  "&:hover": {
                    boxShadow: "none",
                    border: "none",
                    backgroundColor: "transparent",
                  },
                }}
              >
                <CustomTableCell>
                  {t("BUSINESS_SETTING_TRANSACTION_STATUS")}
                </CustomTableCell>
                <CustomTableCell>{t("BUSINESS_SETTING_TRANSACTION_DATE")}</CustomTableCell>
                <CustomTableCell>{t("BUSINESS_SETTING_TRANSACTION_BY")}</CustomTableCell>
                <CustomTableCell>{t("EDIT_BUSINESS_TABLE_FOR")}</CustomTableCell>
                <CustomTableCell>
                  {t("BUSINESS_SETTING_TRANSACTION_CREDITS")}
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list &&
                list
                  .sort((a, b) =>
                    new Date(b.createdAt) < new Date(a.createdAt) ? -1 : 1,
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((transaction) => {
                    return transaction.type === "DEBIT" ? (
                      <DebitTransaction
                        key={`Debit${transaction.id}`}
                        transaction={transaction}
                      />
                    ) : (
                      <CreditTransaction
                        key={`Credit${transaction.id}`}
                        transaction={transaction}
                      />
                    );
                  })}
            </TableBody>
          </Table>
          <TablePagination
            sx={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('MUI_ROWS_PER_PAGE')}
            labelDisplayedRows={({ from, to, count }) => t('MUI_PAGINATION_RANGE', { from, to, count })}
            getItemAriaLabel={(type) => t(`MUI_PAGINATION_${type.toUpperCase()}_PAGE`)}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default AddBusinessCredits;