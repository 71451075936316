import { IORGRegistrationRequest } from './../utils/contracts';
import { axiosInstance } from 'utils/AxiosInstance';
import { API_PATHS } from 'utils/constants';
export const signupOrganisation = async (
  signupData: IORGRegistrationRequest
) => {
  return await axiosInstance.post(API_PATHS.ORG_REGISTRATION, signupData);
};

export const GetUserOrganization = async (orgID: string): Promise<any> => {
  return await axiosInstance.get(API_PATHS.GET_ORGANISATION + orgID);
};
