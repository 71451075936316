import UseAuthContext from 'context/AuthContext';
import { useContext } from 'react';
import { useRoutes } from 'react-router-dom';
import { AuthUserRoutes, getRoutes, publicRoutes } from 'routes';

const RoutesComponent = () => {
  const { role, isAuthenticated, user } = useContext(UseAuthContext);

  const routes =
    isAuthenticated && user
      ? getRoutes(role)
      : [...publicRoutes, ...AuthUserRoutes];
  const content = useRoutes(routes);
  return <>{content}</>;
};

export default RoutesComponent;
