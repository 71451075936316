import { Box, IconButton, Tooltip } from '@mui/material';
import UseLanguageContext from 'context/LanguageContext';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Importez les images de drapeaux
import israelFlag from 'assets/flag_of_Israel.svg';
import ukFlag from 'assets/flag_of_the_United_Kingdom.svg';

const LanguageSwitcher = () => {
  const { language, setLanguage } = useContext(UseLanguageContext);
  const [currentLanguage, setCurrentLanguage] = useState(language);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  const handleChange = (newLanguage: string) => {
    setLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
  };

  return (
    <Box dir="ltr" sx={{ display: 'flex', gap: 2 }}>
      <Tooltip title={t('Switch to English')}>
        <IconButton 
          onClick={() => handleChange('en')}
          sx={{ opacity: currentLanguage === 'en' ? 0.5 : 1 }}
        >
          <img src={ukFlag} alt="English" width="24" height="24" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('Switch to Hebrew')}>
        <IconButton 
          onClick={() => handleChange('he')}
          sx={{ opacity: currentLanguage === 'he' ?   0.5 : 1 }}
        >
          <img src={israelFlag} alt="Hebrew" width="24" height="20" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default LanguageSwitcher;