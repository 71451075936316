import UseAuthContext from 'context/AuthContext';
import { useContext } from 'react';

const Restrict = (props: any) => {
  const { role } = useContext(UseAuthContext);
  const { children, forbidden } = props;
  if (!children) {
    return null;
  }
  if ((forbidden || []).includes(role)) {
    return null;
  }

  return children || null;
};

export default Restrict;
