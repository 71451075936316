import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableHead,
  TablePagination
} from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { visuallyHidden } from '@mui/utils';
import { AxiosResponse } from 'axios';
import ButtonIcon from 'components/ButtonIcon';
import CustomTableCell from 'components/CustomTableCell';
import { CustomTableFontCell } from 'components/CustomTableFontCell';
import GenericDialog from 'components/GenericDialog';
import Spinner from 'components/Spinner';
import { StyledTableSortLabel } from 'components/TableSortedLabel';
import UseAuthContext from 'context/AuthContext';
import { debounce } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getOrganisationLists } from 'services/admin-organisations';
import { axiosInstance } from 'utils/AxiosInstance';
import { API_PATHS } from 'utils/constants';
import { IErrorResponse, Order } from 'utils/contracts';
import {
  calcTotalAccountPoints,
  getComparator,
  getErrorHtmlContent,
  stableSort,
  totalDebitPoints,
} from 'utils/utils';
import OrganisationFilter from './OrganisationFilter';

let DEFAULT_ORDER = 'desc',
  DEFAULT_ORDER_BY = 'updatedAt';



const TableRowWithHover = ({ item, handleViewBusinessPage, setOrganisation, navigate, data, t, setAnchorElMenu, setMenuOrgId, menuOrgId, anchorElMenu, handleCloseMenu, deleteOrganisationConfirmation }) => {
  const [hover, setHover] = useState(false); // New state for hover

  return (
    <TableRow
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        cursor: 'pointer',
      }}
      key={item.id}
    >
      <CustomTableCell onClick={(e) => handleViewBusinessPage(e, item)}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 1,
          }}
        >
          {item.image_url ? (
            <img src={item.image_url} width="30px" />
          ) : null}
          {item.company_name}
        </Box>
      </CustomTableCell>
      <CustomTableCell onClick={(e) => handleViewBusinessPage(e, item)}>
        {item.projectCount}
      </CustomTableCell>

      <CustomTableCell onClick={(e) => handleViewBusinessPage(e, item)}>
        <span className="data">
          {calcTotalAccountPoints(item)}
        </span>
      </CustomTableCell>
      <CustomTableCell onClick={(e) => handleViewBusinessPage(e, item)}>
        <span className="data">
          {calcTotalAccountPoints(item) + totalDebitPoints(item)}
        </span>
      </CustomTableCell>
      <CustomTableCell onClick={(e) => handleViewBusinessPage(e, item)}>
        <span className="data">
          {moment
            .utc(item.updatedAt)
            .local()
            .format("DD/MM/YY")}
        </span>
        <span className="time" style={{ marginLeft: "10px" }}>
          {moment.utc(item.updatedAt).local().format("HH:mm")}
        </span>
      </CustomTableCell>
      <CustomTableCell >
        <ButtonIcon
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            setAnchorElMenu(event.currentTarget);
            setMenuOrgId(item.id);
          }}
        >
          <MoreHorizIcon fontSize='medium' />
        </ButtonIcon>
        <Menu
          id={`menu-${item.id}`}
          open={menuOrgId === item.id}
          anchorEl={anchorElMenu}
          onClose={handleCloseMenu}
        >
          <div>
          <MenuItem onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(`/edit/business/${item.id}`);
            }}>{t('BUSINESS_MENU_EDIT')}</MenuItem>
            <MenuItem onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOrganisation(
                data.find((x: any) => x.id == item.id)
              );
              navigate(`/admin/business/${item.id}/projects`);
            }}>{t('BUSINESS_MENU_VIEW')}</MenuItem>
            <MenuItem
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                deleteOrganisationConfirmation(item.id);
              }}>{t('BUSINESS_MENU_DELETE')}</MenuItem></div>
        </Menu>
      </CustomTableCell>
    </TableRow>
  );
};

const AdminDashboard = () => {
  const {
    data,
    isLoading,
    status,
    refetch: refetchOrganization,
  } = useQuery('GetOrganisations', getOrganisationLists);
  console.log(data);
  const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [openDeletePopUp, setOpenDeletePopUp] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [menuOrgId, setMenuOrgId] = useState<string | null>(null);
  const [businessData, setBusinessData] = useState<any>([]);
  const [order, setOrder] = React.useState<Order>(DEFAULT_ORDER as Order);
  const [orderBy, setOrderBy] = React.useState<string>(DEFAULT_ORDER_BY);
  const { setOrganisation } = React.useContext(UseAuthContext);

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };



  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteMutation = useMutation({
    mutationFn: async (orgId: string) => {
      const response = await axiosInstance.delete<any, AxiosResponse<any>>(
        `${API_PATHS.ADMIN_DELETE_ORGANIZATION}/${orgId}`
      );
      return response;
    },
    onSuccess() {
      setOpenDeletePopUp(false);
      refetchOrganization();
      enqueueSnackbar(t('DELETE_ORGANIZATION_SUCCESS_MESSAGE'), {
        variant: 'success',
      });
    },
    onError(error: IErrorResponse, variables, context) {
      setOpenDeletePopUp(false);
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error, variables, context);
      }
    },
  });

  const deleteOrganisationHandler = async (orgID: any) => {
    try {
      await deleteMutation.mutateAsync(orgID);
    } catch (error: any) { }
  };

  const deleteOrganisationConfirmation = (orgID: any) => {
    setOpenDeletePopUp(true);
    setAnchorEl(orgID);
  };

  useEffect(() => {
    if (data) {
      let rowsOnMount = stableSort(
        data,
        getComparator(DEFAULT_ORDER as Order, DEFAULT_ORDER_BY)
      );
      setBusinessData(rowsOnMount);
    }
  }, [data, status]);

  const handleViewBusinessPage = (e: React.MouseEvent<unknown>, item: any) => {
    e.preventDefault();
    e.stopPropagation();
    setOrganisation(
      data.find((x: any) => x.id == item.id)
    );
    navigate(`/admin/business/${item.id}/projects`);
  }


  const handleRequestSort = React.useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: string) => {
      const isAsc = orderBy === newOrderBy && order === 'asc';
      const toggledOrder = isAsc ? 'desc' : 'asc';
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
      const sortedRows = stableSort(
        businessData,
        getComparator(toggledOrder, newOrderBy)
      );
      setBusinessData(sortedRows);
    },
    [order, orderBy, page, rowsPerPage, data, businessData]
  );

  const createSortHandler =
    (newOrderBy: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, newOrderBy);
    };

  const debouncedSearch = debounce(async (criteria) => {
    if (data && data.length > 0) {
      criteria = criteria.toLowerCase();
      const filteredData = data.filter((business: any) => {
        return (
          (business.company_email?.toLowerCase().indexOf(criteria) ?? -1) >
          -1 ||
          (business.company_name?.toLowerCase().indexOf(criteria) ?? -1) > -1
        );
      });

      setBusinessData(filteredData ?? []);
    }
  }, 300);

  const handleClose = () => {
    setOpenDeletePopUp(false);
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
    setMenuOrgId(null);
  };

  async function handleChange(value: string) {
    debouncedSearch(value);
  }

  const handleDownloadBusinessData = () => {
    const maxMembers = 5; // Définissez le nombre maximum de membres à inclure

    const createMemberHeaders = (count) => {
      let headers = [];
      for (let i = 1; i <= count; i++) {
        headers.push(`Membre ${i} Nom,Membre ${i} Email,Membre ${i} Téléphone`);
      }
      return headers.join(',');
    };

    const createMemberData = (members) => {
      let data = [];
      for (let i = 0; i < maxMembers; i++) {
        if (i < members.length) {
          data.push(`${members[i].name || ''},${members[i].email || ''},${members[i].phone || ''}`);
        } else {
          data.push(',,'); // Cellules vides pour les membres manquants
        }
      }
      return data.join(',');
    };

    const csvContent = "data:text/csv;charset=utf-8,"
      + `Nom de l'entreprise,Téléphone de l'entreprise,Email de l'entreprise,Crédits,Total,Date de mise à jour,Nombre de projets,${createMemberHeaders(maxMembers)}\n`
      + data.map(item => {
        return `${item.company_name},${item.company_phone || ''},${item.company_email},${calcTotalAccountPoints(item)},${calcTotalAccountPoints(item) + totalDebitPoints(item)},${moment.utc(item.updatedAt).local().format("DD/MM/YY HH:mm")},${item.projectCount},${createMemberData(item.members)}`
      }).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "business_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <OrganisationFilter
          handleSearch={handleChange}
        />
        <Box display={'flex'} alignItems={"center"}>
          <ButtonIcon
            onClick={handleDownloadBusinessData}>
            <FileDownloadOutlinedIcon fontSize='large' />
          </ButtonIcon>
        </Box>
      </Box>

      <Table size='small'>
        <TableHead >
          <TableRow
            sx={{
              "&:hover": {
                boxShadow: "none",
                border: "none",
                backgroundColor: "transparent",
              },
            }}>                {/* <TableCell>{t('ADMIN_DASHBOARD_NAME')}</TableCell> */}
            <CustomTableFontCell
              sx={{ minWidth: "250px" }}
              sortDirection={orderBy === "company_name" ? order : false}
            >
              <StyledTableSortLabel 
                active={orderBy === "company_name"}
                direction={orderBy === "company_name" ? order : "asc"}
                onClick={createSortHandler("company_name")}
              >
                {t("ADMIN_DASHBOARD_NAME")}
                {orderBy === "company_name" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </StyledTableSortLabel>
            </CustomTableFontCell>
            <CustomTableFontCell sortDirection={orderBy === "projectCount" ? order : false}>
              <StyledTableSortLabel
                active={orderBy === "projectCount"}
                direction={orderBy === "projectCount" ? order : "asc"}
                onClick={createSortHandler("projectCount")}
              >
                {t("ADMIN_DASHBOARD_TOTAL_PROJECTS")}
                {orderBy === "projectCount" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </StyledTableSortLabel>
            </CustomTableFontCell>
            <CustomTableFontCell sortDirection={orderBy === "credit" ? order : false}>
              <StyledTableSortLabel
                active={orderBy === "credit"}
                direction={orderBy === "credit" ? order : "asc"}
                onClick={createSortHandler("credit")}
              >
                {t("ADMIN_DASHBOARD_CREDITS")}
                {orderBy === "credit" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </StyledTableSortLabel>
            </CustomTableFontCell>
            <CustomTableFontCell sortDirection={orderBy === "credit" ? order : false}>
              <StyledTableSortLabel
                active={orderBy === "purchased"}
                direction={orderBy === "purchased" ? order : "asc"}
                onClick={createSortHandler("purchased")}
              >
                {t("ADMIN_DASHBOARD_PURCHASED")}
                {orderBy === "purchased" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </StyledTableSortLabel>
            </CustomTableFontCell>
            <CustomTableFontCell
              sortDirection={orderBy === "updatedAt" ? order : false}
            >
              <StyledTableSortLabel
                active={orderBy === "updatedAt"}
                direction={orderBy === "updatedAt" ? order : "asc"}
                onClick={createSortHandler("updatedAt")}
              >
                {t("ADMIN_DASHBOARD_DATE")}
                {orderBy === "updatedAt" ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </StyledTableSortLabel>
            </CustomTableFontCell>
            <CustomTableFontCell align="right"></CustomTableFontCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {businessData && businessData.length > 0 ? (
            businessData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item: any) => (
                <TableRowWithHover
                  key={item.id}
                  item={item}
                  handleViewBusinessPage={handleViewBusinessPage}
                  setOrganisation={setOrganisation}
                  navigate={navigate}
                  data={data}
                  t={t}
                  setAnchorElMenu={setAnchorElMenu}
                  setMenuOrgId={setMenuOrgId}
                  menuOrgId={menuOrgId}
                  anchorElMenu={anchorElMenu}
                  handleCloseMenu={handleCloseMenu}
                  deleteOrganisationConfirmation={deleteOrganisationConfirmation}
                />
              ))
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
      <TablePagination
        sx={{ width: "100%" }}
        rowsPerPageOptions={[5, 10, 25]}
        count={businessData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        component={"div"}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t('MUI_ROWS_PER_PAGE')}
        labelDisplayedRows={({ from, to, count }) => t('MUI_PAGINATION_RANGE', { from, to, count })}
        getItemAriaLabel={(type) => t(`MUI_PAGINATION_${type.toUpperCase()}_PAGE`)}
      />
      <GenericDialog
        open={openDeletePopUp}
        onClose={handleClose}
        title={t("ORGANISATION_DELETE_POPUP_TITLE")}
        onConfirm={(e) => deleteOrganisationHandler(anchorEl)}
        confirmButtonText={t("ORGANISATION_DELETE_YES")}
        cancelButtonText={t("ORGANISATION_DELETE_NO")}
      >
        {t("ORGANISATION_DELETE_POPUP_DESCRIPTION")}
      </GenericDialog>
    </Box>
  );
};

export default AdminDashboard;