import React from 'react';

const Spinner = () => {
  return (
    <div className='loader-container'>
      <div className='loader-spinner'></div>
    </div>
  );
};

export default Spinner;
