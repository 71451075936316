import { Facebook, LinkedIn, YouTube } from '@mui/icons-material';
import { Box, Grid, Link } from '@mui/material';
import HeaderMenu from './HeaderMenu';
const Footer = () => {
  return (
    <Box
      className='footer'
      sx={{
        py: "30px",
      }}
    >
      <Grid container justifyContent='space-between' alignItems='center' flexWrap={"nowrap"}>
        <Box className='menu-items menu-footer' sx={{alignItems:"center"}}>
          <HeaderMenu isForHeader={false} />
        </Box>
        <div  >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              flexWrap: "nowrap"
            }}
          >
            <Link
              style={{ padding: "0" }}
              href='https://www.facebook.com/DesignBuilderIsrael/'
              target='_blank'
              rel='noreferrer'
              display={"flex"}
            >
              <Facebook />
            </Link>
            <Link
              display={"flex"}

              href='https://www.youtube.com/playlist?list=PLu8h-kasuylHzBBmA_3pCtdMYDnmuyF-A'
              target='_blank'
              rel='noreferrer'
            >
              <YouTube />
            </Link>

            <Link
              display={"flex"}

              href='https://www.linkedin.com/company/designbuilder-israel/about/?viewAsMember=true'
              target='_blank'
              rel='noreferrer'
            >
              <LinkedIn />
            </Link>
          </Box>
        </div>
      </Grid>
    </Box>
  );
};

export default Footer;
