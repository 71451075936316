import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import React from 'react';
import ButtonCustom from './ButtonCustom';

interface GenericDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  cancelButtonText: string;
  children: any;
  icon?: React.ReactNode;
  confirmButtonText?: any;
  onConfirm?: any;
}

const GenericDialog: React.FC<GenericDialogProps> = ({ open, onClose, title, children, icon, onConfirm, cancelButtonText, confirmButtonText }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth='md' fullWidth={true} sx={{ marginBottom: "50px" }}>
      <DialogTitle>
        {icon && (
          <Box>
            {icon}
          </Box>
        )}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogTitle id="alert-dialog-title" >
        <Typography textAlign={"center"} variant='h2'>
        {title}
        </Typography>
      </DialogTitle>
      <DialogContent className="dialog-content">
        <DialogContentText id="alert-dialog-description"><Typography textAlign={"center"}  color='#2F3B49' fontSize={"18px"}>{children}</Typography></DialogContentText>
      </DialogContent>
      <DialogActions dir='ltr'  sx={{ display: 'flex', gap: 2, justifyContent: 'center', paddingBottom: "20px",}}>
        <ButtonCustom onClick={onClose} color='secondary' className='secondary-btn'
          variant="outlined">
          {cancelButtonText}
        </ButtonCustom>
        <ButtonCustom onClick={onConfirm} color="primary" variant="contained">
          {confirmButtonText}
        </ButtonCustom>
      </DialogActions>
    </Dialog>
  );
};

export default GenericDialog;