import UseAuthContext from 'context/AuthContext';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { USER_ROLES } from 'utils/constants';

const ProtectedRoute = (props: any) => {
  const { isAuthenticated, user, auth, logout, role } =
    useContext(UseAuthContext);
  const location = useLocation();

  if (!isAuthenticated) {
    logout(location.pathname);
  }
  const isAdmin = role && role.toLowerCase() === USER_ROLES.ADMIN;
  if (isAuthenticated && (user || isAdmin) && auth) {
    const decoded = jwt_decode<any>(auth.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      isAdmin ? logout(location.pathname) : logout(location.pathname);
    }
  }

  return props.children;
};

export default ProtectedRoute;
