export function monthDiff(d1: any, d2: any) {
  let fromDate = d1;
  let toDate = d2;
  if (typeof fromDate === 'string') {
    fromDate = new Date(d1);
  }
  if (typeof toDate === 'string') {
    toDate = new Date(d2);
  }
  let months;
  months = (toDate.getFullYear() - fromDate.getFullYear()) * 12;
  months -= fromDate.getMonth() + 1;
  months += toDate.getMonth();
  return months <= 0 ? 0 : months;
}

export function calcCreditedPoints(transaction: any) {
  if (transaction.monthly === true) {
    let diffInMonths = monthDiff(transaction.createdAt, new Date());
    return (diffInMonths + 1) * (transaction.points / 12);
  } else {
    return transaction.points;
  }
}

export function calcTotalAccountPoints(organization: any) {
  const { transactions } = organization;

  let list = [];
  for (let property in transactions) {
    if (transactions.hasOwnProperty(property)) {
      list.push(transactions[property]);
    }
  }

  let points = 0;
  list &&
    list
      .filter((a) => a.status === 'success')
      .sort((a, b) => b.createdAt - a.createdAt)
      .forEach((transaction) => {
        if (transaction.type === 'CREDIT' && transaction.status === 'success') {
          points += calcCreditedPoints(transaction);
        } else if (
          transaction.status === 'success' &&
          transaction.type === 'DEBIT'
        ) {
          if (!transaction.usedPowerCredits) {
            points -= transaction.points;
          }
        }
      });
  return points;
}
