import { Box, TextField } from '@mui/material';
import ButtonCustom from 'components/ButtonCustom';
import ImageDropZone from 'components/ImageDropZone';
import Restrict from 'components/Restrict';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addOrganisation } from 'services/business-setting-service';

const AddBusinessSetting = (props: any) => {
  const { organisationCallback } = props;
  const { t } = useTranslation();
  const [preview, setPreview] = useState(null);
  const [image_url, setImageUrl] = useState(null);
  const [image_error, setImageError] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [isLoading, setisLoading] = useState(false);

  const onImageDrop = (files: any, maxSize: any) => {
    const file = files[0];
    let reader = new FileReader();

    reader.onloadend = (e: any) => {
      setPreview(e.target.result);
      setImageUrl(null);
    };

    if (file) {
      if (file.size > maxSize) {
        setImageError(t('IMAGE_SIZE_ERROR'));
        return;
      }

      reader.readAsDataURL(file);
    }
  };

  const removeImage = (e: any) => {
    e.preventDefault();
    setPreview(null);
    setImageUrl(null);
    e.stopPropagation();
  };

  const onClickHandler = async (e: any) => {
    try {
      setisLoading(true);
      e.preventDefault();
      const data = await addOrganisation({
        image_url: image_url ?? '',
        company_name: name,
        company_address: address,
        preview: preview,
      });
      if (data.status === 200) {
        setisLoading(false);
        organisationCallback(data.data.id);
      }
    } catch (error) {
      setisLoading(false);
    }
  };

  return (
    <Box
      component='form'
      sx={{
        mx: '2rem',
        display: 'flex',
        alignItems: 'flex-start',
        gap: '2rem',
      }}
      className='tab-content-form'
    >
      <Box sx={{ flexGrow: 1 }}>
        <TextField
          margin='normal'
          fullWidth
          variant='standard'
          id='name'
          name='name'
          type='text'
          autoFocus
          label={t('EDIT_BUSINESS_SETTING_NAME')}
          autoComplete='off'
          autoCorrect='off'
          helperText={t('EDIT_BUSINESS_SETTING_NAME_HELPER_TEXT')}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin='normal'
          fullWidth
          variant='standard'
          id='address'
          name='address'
          type='text'
          label={t('EDIT_BUSINESS_SETTING_ADDRESS')}
          autoComplete='off'
          autoCorrect='off'
          helperText={t('EDIT_BUSINESS_SETTING_ADDRESS_HELPER_TEXT')}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <Restrict forbidden={['viewer']}>
          <ButtonCustom
            color='primary'
            sx={{ mt: 3, mb: 2 }}
            type='submit'
            variant='contained'
            size='large'
            onClick={onClickHandler}
          >
            {t('ADD_BUSINESS_SETTING_SAVE_BUTTON')}
          </ButtonCustom>
        </Restrict>
      </Box>
      <Box className='business-setting'>
        <ImageDropZone
          onImageDrop={onImageDrop}
          preview={preview}
          image_url={image_url}
          onImageRemove={removeImage}
          enabled={true}
          size={500000}
          image_error={image_error}
        />
      </Box>
    </Box>
  );
};

export default AddBusinessSetting;
