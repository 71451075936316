import { SearchOutlined } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear'; // Import du logo pour effacer
import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import ButtonIconText from 'components/ButtonIconText';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const OrganisationFilter = ({ handleSearch }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [value, setValue] = useState("");

  const handleChangeSearchText = (e: any) => {
    
    setValue(e.target.value);
    handleSearch(e.target.value);
  };

  const handleClear = () => {
    setValue("");
    handleSearch("");
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height:"144px",
        mb:"22px"
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '2rem',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '24px',
              lineHeight: '31px',
              color: '#2F3B49',
            }}
          >
            {t('ADMIN_DASHBOARD_TITLE')}
          </Typography>
        </Box>
        <Box>
          <ButtonIconText
            color='primary'
            disableElevation={true}
            type='submit'
            variant='contained'
            size='large'
            onClick={(e: any) => {
              e.preventDefault();
              navigate('/admin/add-business');
            }}
      >
            {t('ADMIN_DASHBOARD_ADD_ORGANISATION')}
      </ButtonIconText>
         
        </Box>
        <Box>
          <TextField
            id='standard-bare'
            size='small'
            variant='outlined'
            placeholder={t('ADMIN_ORGANISATION_SEARCH_PLACEHOLDER')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {value ? (
                    <IconButton onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    <IconButton>
                      <SearchOutlined />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
              sx: { height: '50px' } // Augmenter la hauteur de l'input
            }}
            value={value}
            onChange={handleChangeSearchText}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OrganisationFilter;