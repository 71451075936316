import S3 from './S3';
import Cloudinary from './Cloudinary';

const getStrategy = (mimeType: any, fileName: any, size: any) => {
  if (mimeType.indexOf('image') >= 0) {
    return Cloudinary;
  } else {
    return S3;
  }
};

export default {
  getStrategy: getStrategy,
};
