import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { Box, Typography } from '@mui/material';
import FileLogo from 'assets/file-icon.svg';
import { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';



const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '311px',
  width: '311px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out',
  marginTop: '3rem',
  textAlign: "center"
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const disabledStyle = {
  opacity: 0.5,
  pointerEvents: 'none',
};

const ImageDropZone = (props: any) => {
  const {
    mode,
    preview,
    image_url,
    onImageDrop,
    onImageRemove,
    size,
    image_error,
    enabled,
    customStyle,
  } = props;

  const onDrop = useCallback((acceptedFiles: any) => {
    if (enabled) {
      onImageDrop(acceptedFiles, size);
    }
  }, [enabled, onImageDrop, size]);

  const { t } = useTranslation();
  const imageData = preview || image_url || null;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.jpeg', '.jpg'] },
    disabled: !enabled,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(!enabled ? disabledStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, enabled]
  );

  return !preview && !image_url ? (
    <div {...getRootProps({ style: { ...style, ...customStyle } } as any)}>
      <input {...getInputProps()} />
      <img className='' src={FileLogo} alt='file' width={customStyle ? 50 : 106} style={{ opacity: "0.2" }} />

      <div style={{ paddingTop: "10px" }}>{t('DROPZONE_PLACEHOLDER')}</div>
      <Typography variant='caption' color='error'>
        {image_error}
      </Typography>
    </div>
  ) : (
    <div className='file-upload-content show crop-container' style={customStyle}>
      {image_url && (
        <img
          className={`file-upload-image ${mode}`}
          style={{ objectFit: 'cover', width: '100%' }}
          src={imageData}
          alt='alt'
        />
      )}
      {preview && !image_url && (
        <img
          className={`file-upload-image ${mode} crop`}
          src={imageData}
          alt='alt'
        />
      )}

      {!onImageRemove ? null : !enabled ? null : (
        <Box sx={{
          position: 'absolute',
          top: 10,
          right: 10,
        }} className='file-upload-btn'>
          <CloseSharpIcon
            onClick={onImageRemove}
            sx={{
              cursor: 'pointer',
              color: '#000',
              border: '1.5px black solid',
              borderRadius: '50%',
              p: "2px"
            }}
          />
        </Box>
      )}
    </div>
  );
};

export default ImageDropZone;