import { Box, BoxProps } from '@mui/material';

const NoFlipBox = (props: BoxProps) => (
  <Box
    {...props}
    sx={{
      ...props.sx,
      '& .MuiGrid-root': {
        /* @noflip */
				direction:"ltr",
				flexDirection: "row-reverse",
        textAlign: 'left',
      },
    }}
  />
);

export default NoFlipBox;