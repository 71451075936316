import { styled, TableSortLabel } from "@mui/material";

export const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  "&.Mui-active": {
    color: theme.palette.primary.main,
  },
  ".MuiTableSortLabel-icon:hover": {
    color: "black",
    backgroundColor: "#D3D3D3",
    borderRadius: "50%",
    border: "none",
  },
  ".MuiTableSortLabel-icon": {
    border: "1px solid #D3D3D3",
    color: "black",
    borderRadius: "50%",
  },
  "&.Mui-active .MuiTableSortLabel-icon": {
    border: "none",
  },
  "&.Mui-active, .MuiTableSortLabel-icon:hover": {
    backgroundColor: "none !important",
  }
}));