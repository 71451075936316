import { Container, Grid2 } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

export const Layout = () => {
  return (
    <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight:"100vh"}}>
      <Grid2 sx={{ width: "100%" }}>
        <Header />
      </Grid2>
      <Grid2 className={"body-container"} sx={{ flex: 1 }}>
        <Outlet />
      </Grid2>
      <Grid2 sx={{ width: "100%" }} component='footer'>
        <Footer />
      </Grid2>
    </Container>
  );
};
