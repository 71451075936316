import axios from 'axios';
import { Cloudinary } from "@cloudinary/url-gen";
import { axiosInstance } from 'utils/AxiosInstance';
import { API_PATHS } from 'utils/constants';

/* const setUp = () => {
  const config = {
    cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
    api_key: process.env.REACT_APP_CLOUDINARY_APIKEY,
    api_secret: process.env.REACT_APP_CLOUDINARY_APISECRET,
  };
}; */

const getUploadSignature = async (publicId :any,size:any) => {
  try {
    const response = await axiosInstance.post('cloudinary/signature',{
      publicId,
      size
    }); // Remplacez par le bon endpoint
    return response.data; // La réponse contiendra la signature et d'autres informations nécessaires
  } catch (error) {
    console.error('Error fetching upload signature:', error);
    throw error;
  }
}; 

const uploadObject = async ({ publicId, file,type, destination, size }: any) => {
  const { signature, api_key, timestamp,cloud_name} = await getUploadSignature(publicId,size);
  // Créez un formulaire ou des paramètres pour l'upload en utilisant la signature
  const formData = new FormData();
  formData.append('file', file);
  formData.append('api_key', api_key);
  formData.append('signature', signature);
  formData.append('timestamp', timestamp);
  formData.append("eager", 'w_280,h_280,c_crop');
  formData.append('public_id', publicId);

  try {
    const uploadResponse = await axios.post(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    console.log('Upload success:', uploadResponse.data);
    return {webContentLink:  uploadResponse.data.url}
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};

/* const uploadObject = (
  { publicId, id, type, file, destination, size }: any,
  onProgress: any
) => {
  if (type.indexOf('image') >= 0) {
    const { width, height } = size;
    return new Promise((resolve, reject) => {
      setUp();
   
      cloudinary.v2.uploader
        .upload_stream(
          {
            resource_type: 'image',
            public_id: destination,
            height: height,
            width: width,
            quality: 'auto:best',
            crop: 'thumb',
          },
          (error: any, result: any) => {
            if (result) {
              resolve({
                fileEtag: result.etag,
                publicId: 'cl/' + result.public_id,
                webContentLink: result.secure_url,
              });
            } else {
              reject(error);
            }
          }
        )
        .end(file);
    });
  } else {
    throw 'unsupported file';
  }
}; */

export default {
  uploadObject: uploadObject,
  deleteObject: null,
};
