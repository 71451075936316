import { config } from 'config';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ILanguageContext {
  language?: string;
  setLanguage: (language: string) => void;
}

const UseLanguageContext = createContext<ILanguageContext>(
  {} as ILanguageContext
);

export const LanguageProvider = ({ children }: any) => {
  const { i18n } = useTranslation();
  const [language, setLanguageState] = useState<string>(() => {
    const storedLanguage = localStorage.getItem('language');
    return storedLanguage || config.LANGUAGE || 'he';
  });

  useEffect(() => {
    if (!language) {
      const defaultLanguage = navigator.language.includes('he') ? 'he' : 'en';
      setLanguageState(defaultLanguage);
      i18n.changeLanguage(defaultLanguage);
      localStorage.setItem('language', defaultLanguage);
    } else {
      i18n.changeLanguage(language);
    }
  }, []);

  const setLanguage = (newLanguage: string) => {
    setLanguageState(newLanguage);
    localStorage.setItem('language', newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  return (
    <UseLanguageContext.Provider
      value={{ language, setLanguage }}
      key={'LANGUAGECONTEXT'}
    >
      {children}
    </UseLanguageContext.Provider>
  );
};

export default UseLanguageContext;
